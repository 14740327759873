.lds-facebook {
    display: inline-block;
    position: relative;
    width: 50px;
  }
  .lds-facebook div {
    display: inline-block;
    position: absolute;
    width: 10px;
    background: grey;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .lds-facebook div:nth-child(1) {
    
    left: 8px;
    animation-delay: -0.24s;
  }
  .lds-facebook div:nth-child(2) {
    background: rgb(52, 51, 51);
    left: 32px;
    animation-delay: -0.12s;
  }
  .lds-facebook div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
  }
  @keyframes lds-facebook {
    0% {
      top: 4px;
      height: 54px;
    }
    50%, 100% {
      top: 20px;
      height: 22px;
    }
  }
  