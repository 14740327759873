@media (max-width: 1199px) and (min-width: 992px) {
    .navbar {
        padding-right: calc((100% - 992px)/ 2 + 15px);
        padding-left: calc((100% - 992px)/ 2 + 15px);
    }
}

@media (max-width: 1199px) and (min-width: 992px) {
    /*.app-main, */
    .menubar {
        padding-right: calc((100% - 992px)/ 2 + 30px);
        padding-left: calc((100% - 992px)/ 2 + 30px);
    }
}

.navbar-container{
    height: 150px;
    display: flex;
    flex-direction: column;
}

.navbar {
    background: rgb(34, 32, 32);
    color: white;
    height: 70px;
}

.navbar-fixed-top {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
}

.menubar{
    background-color: white;
    color: grey;
}

.bar-toggle{

}
@media (max-width: 768px){
    /*.app-main, */
    .bar-toggle {
        display: none;
    }
}
.activeClass{
    font-weight: 800;
    color: #ffffff!important;
}
.link{
    font-weight: 500;
}