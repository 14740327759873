*{
    margin: 0;
    padding: 0;
    user-select: none;
    box-sizing: border-box;
}

.rotate-arrow-svg{
    transform: rotate(-180deg);
}

#sidebar{
    
}

.hide-side-bar{
    left: -2000px !important;
}