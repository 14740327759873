@import url('https://fonts.googleapis.com/css?family=Quicksand:100,200,300,400,500,600,700');

body {
  margin: 0;
  font-family: 'Quicksand',Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #edf0f5;
  font-size: 14px;
  line-height: 1.428571429;
}

.main-content{
  padding-right: calc((100% - 992px)/ 2 + 2px);
  padding-left: calc((100% - 992px)/ 2 + 2px);
  /* /* min-height: calc(100% - 60px); */
}

@media (max-width:768px) {
  .main-content{
    padding: 0;
    width: 100%;
  }
}

.main-content-margin-top{
  margin-top: 70px;
}

.red{color: #ff5b5b;}.green{color: #10c469 !important;}.yellow{color: #f9c851 !important;}.blue{color: #188ae2 !important;}

.margin-right-left-auto{
  margin-right: auto !important;
  margin-left: auto !important;
}

.bold{font-weight: bold !important;}.bold-500{font-weight: 500 !important;}


.hide{
  display: none;
}

.profile-active{
  background: #dab5b3;
}

.unstyle-href{
  color: inherit;
  text-decoration: none;
}

.equal-flex-item{
  width:50%;
}

.margin-top-10{margin-top: 10px;}.margin-bottom-10{margin-bottom: 10px;}.margin-top-20{margin-top: 20px;}.margin-bottom-20{margin-bottom: 20px;}


.paystack-button {
  cursor: pointer;
  text-align: center;
  font-size: 10px;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  background-color: #bfbfbf;
  font-weight: bold;
  color: #e0eafc;
  border: none;
  border-radius: 5px;
  width: 100%;
  height: 105px;
  margin-top: 40px;
}